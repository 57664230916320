// 导入Vue Router的必要功能模块
import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext } from 'vue-router'

// 导入认证和应用布局组件
import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
// 新增加的AI布局组件
import AiLayout from '../layouts/AiLayout.vue'
import HomeLayout from '../layouts/HomeLayout.vue' // 新增 HomeLayout

// 导入一个用于路由绕过的组件
import RouteViewComponent from '../layouts/RouterBypass.vue'

// 导入用户存储
import { useUserStore } from '../stores/user-store'

// 定义路由数组，每个路由条目都是一个对象
const routes: Array<RouteRecordRaw> = [
  {
    // 新增 home 路由作为默认页面
    path: '/',
    component: HomeLayout,
    children: [
      {
        name: 'home',
        path: '',
        component: () => import('../pages/home/HomePage.vue'),
      },
      {
        name: 'amazon-auth',
        path: 'amazon-auth',
        component: () => import('../pages/home/AmazonAuth.vue'),
      },
      {
        name: 'contact-us',
        path: 'contact-us',
        component: () => import('../pages/home/ContactUsPage.vue'),
      },
    ],
  },
  {
    // 定义一个名为'admin'的根路由，使用应用布局组件，重定向到'data-insights'
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'data-insights' },
    children: [
      {
        name: 'data-insights',
        path: 'data-insights',
        component: () => import('../pages/data-insights/DataInsights.vue'),
      },
      {
        name: 'trend-following',
        path: 'trend-following',
        beforeEnter: (to, from, next) => {
          // 直接重定向到 real-time 路由
          next({ path: '/real-time' })
        },
        component: () => import('../pages/trend-following/TrendFollowing.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'metrics-optimizer',
        path: 'metrics-optimizer',
        component: () => import('../pages/metrics-optimizer/MetricsOptimizer.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'targeting-optimizer',
        path: 'targeting-optimizer',
        component: () => import('../pages/targeting-optimizer/TargetingOptimizer.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'targeting-hourly',
        path: 'targeting-hourly',
        component: () => import('../pages/targeting-hourly/TargetingHourly.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'refined-operations',
        path: 'refined-operations',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'real-time',
        path: 'real-time',
        component: () => import('../pages/real-time/RealTime.vue'),
      },
      {
        name: 'comparison',
        path: 'comparison',
        component: () => import('../pages/comparison/Comparison.vue'),
      },
      {
        name: 'traffic-analysis',
        path: 'traffic-analysis',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'message',
        path: 'message',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'notification',
        path: 'notification',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
      {
        name: 'helps',
        path: 'helps',
        component: () => import('../pages/helps/Settings.vue'),
      },
      {
        name: 'logout',
        path: 'logout',
        component: () => import('../pages/logout/Settings.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
        meta: { requiresGuest: true },
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
        meta: { requiresGuest: true },
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    path: '/chat', // 将路径设置为 '/chat'
    component: AiLayout,
    children: [
      {
        name: 'ai',
        path: '', // 空路径，表示 /chat
        component: () => import('../pages/ai/AiPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'chat', // 保持名称为 'chat'
        path: ':chat_id', // 设置带有 chat_id 参数的路径
        component: () => import('../pages/ai/ChatPage.vue'), // 指向 ChatPage.vue
        props: true, // 启用 props 传递
        meta: { requiresAuth: true },
      },
      {
        name: 'app',
        path: '/app',
        component: () => import('../pages/ai/AppPage.vue'),
        props: true, // 启用 props 传递
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    // 当URL匹配到任意路径时，重定向到名为'home'的路由
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

// 缓存认证状态
let cachedAuthState: boolean | null = null

// 优化的全局前置守卫
router.beforeEach((to, from, next: NavigationGuardNext) => {
  // 只在必要的路由上应用守卫
  if (!to.meta.requiresAuth && !to.meta.requiresGuest) {
    return next()
  }

  const userStore = useUserStore()

  // 使用缓存的认证状态或重新获取
  if (cachedAuthState === null) {
    cachedAuthState = userStore.isAuthenticated
  }

  // 处理需要登录才能访问的路由
  if (to.meta.requiresAuth && !cachedAuthState) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath },
      params: { message: '请先登录以访问此页面' },
    })
  }

  // 处理只有未登录用户才能访问的路由（如登录和注册页面）
  if (to.meta.requiresGuest && cachedAuthState) {
    const redirectPath = (to.query.redirect as string) || '/trend-following'
    return next(redirectPath)
  }

  // 对于其他所有路由，允许访问
  next()
})

// 添加后置钩子来重置缓存
router.afterEach(() => {
  cachedAuthState = null
})

export default router
